import { Component } from '@angular/core';
import { AbstractIconComponent } from './abstract-icon.component';

@Component({
  selector: 'app-icon-app-advise',
  styleUrls: ['./icons.scss'],
  standalone: true,
  template: `<svg
    [attr.width]="size"
    [attr.height]="size"
    viewBox="0 0 512 512"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="M439,307.6c-10.6-9.9-17.3-24.5-25.9-47.9c-8-21.5-16.3-68.8-17.8-101.4c-2.2-46.3-28.6-68.9-57.8-89.8 c-35.8-25.6-78.8-25.8-82.4-25.6c-3.2,0.5-46.1,0.2-81.7,25.6c-29.2,20.9-55.6,43.5-57.8,89.8c-1.5,32.6-9.8,79.9-17.8,101.4 c-8.7,23.4-15.3,37.9-25.9,47.8c-16,15-33.8,34.9-29.6,55.9c3.9,19.8,19,32.6,41.4,35.1c5.8,0.6,27.9,0.8,54.6,0.8 c19.8,0,41.9-0.1,61.7-0.3c0.1,1.9,0,3.8,0.3,5.6l-12.4,7.2c-1.4,0.8-2.1,2.5-1.6,4.1c3.2,10.4,8.8,19.8,16,27.6 c1.1,1.2,2.9,1.5,4.3,0.7l12.4-7.2c5.2,4.5,11.2,8,17.8,10.3v14.4c0,1.6,1.1,3.1,2.7,3.4c10.7,2.4,21.7,2.3,31.9,0 c1.6-0.3,2.7-1.8,2.7-3.4v-14.4c6.5-2.3,12.5-5.8,17.8-10.3l12.4,7.2c1.4,0.8,3.2,0.6,4.3-0.7c7.2-7.8,12.7-17.2,16-27.6 c0.4-1.6-0.2-3.3-1.6-4.1l-12.4-7.2c0.3-1.8,0.2-3.7,0.3-5.6c46.5,0.3,106.2,0.5,116.3-0.6c22.3-2.5,37.4-15.3,41.3-35.1 C472.8,342.4,455,322.5,439,307.6z M255.5,417.8c-11.3,0-20.3-8.3-22.5-19c10.7-0.1,18.9-0.2,22-0.2h1c3.2,0,11.2,0.1,22,0.2 C275.8,409.5,266.8,417.8,255.5,417.8z M423.8,366.7c-10.6,1.2-108.3,0.6-167.5-0.1c-0.1,0-1.3,0-1.4,0c-59.2,0.7-157,1.3-167.5,0.1 c-11.9-1.3-12.9-6.6-13.5-9.5c0-0.3-0.7-6.8,20.1-26.3c16.7-15.7,25.3-36.5,34-60.1c9-24.4,18.1-75.2,19.7-111 c1.5-32.1,19.4-47.3,44.5-65.3c27.1-19.4,62.6-19.7,63.9-19.7c0.5,0,36,0.4,62.9,19.7c25.1,18,42.9,33.2,44.5,65.3 c1.6,35.8,10.7,86.6,19.7,111c8.7,23.6,17.3,44.4,34,60.1c20.8,19.4,20.1,26,20.1,26.3C436.7,360.1,435.6,365.4,423.8,366.7z"
      />
    </g>
  </svg>`,
})
export class IconAppAdviseComponent extends AbstractIconComponent {}
